@tailwind base;

@layer base {
  @font-face {
    font-family: "PT Serif";
    src: url("./assets/Fonts/PT_Serif/PTSerif-Regular.ttf") format("truetype");

    font-style: normal;
  }

  /* Import Poppins font */
  @font-face {
    font-family: "Poppins";
    src: url("./assets/Fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  html {
    font-family: "Poppins", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "PT Serif", serif;
  }
}

@tailwind components;
@tailwind utilities;

/* 
@layer components {
  .btn-primary {
    @apply py-2 px-5 bg-violet-500 text-white font-semibold rounded-full shadow-md hover:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-400 focus:ring-opacity-75;
  }
} */

/* Custom Components */
@layer components {
  .section-title {
    @apply text-3xl font-bold mb-4 text-gray-800;
  }

  .section-subtitle {
    @apply text-xl font-medium mb-2 text-gray-600;
  }

  .section-description {
    @apply text-base mb-6 text-gray-500;
  }
}

:root {
  --font-size-3xl: 1.875rem;
  /* 30px */
  --font-size-5xl: 3rem;
  /* 48px */
  --font-size-xl: 1.25rem;
  /* 20px */
  --font-size-2xl: 1.5rem;
  /* 24px */
  --font-size-base: 1rem;
  /* 16px */
  --font-size-lg: 1.125rem;
  /* 18px */
}

@media (min-width: 768px) {
  :root {
    --font-size-3xl: 2.25rem;
    /* 36px */
    --font-size-5xl: 3.75rem;
    /* 60px */
    --font-size-xl: 1.5rem;
    /* 24px */
    --font-size-2xl: 1.75rem;
    /* 28px */
    --font-size-base: 1.125rem;
    /* 18px */
    --font-size-lg: 1.25rem;
    /* 20px */
  }
}

@media (min-width: 1024px) {
  :root {
    --font-size-3xl: 3rem;
    /* 48px */
    --font-size-5xl: 4.5rem;
    /* 72px */
    --font-size-xl: 1.75rem;
    /* 28px */
    --font-size-2xl: 2rem;
    /* 32px */
    --font-size-base: 1.25rem;
    /* 20px */
    --font-size-lg: 1.375rem;
    /* 22px */
  }
}

.slider button {
  position: absolute;
  padding: 10px;
  font-size: 15px;
}

.slick-prev {
  left: 10px;
  z-index: 1;
}

.slick-next {
  right: 10px;
  z-index: 1;
}

.slick-prev,
.slick-next {
  font-size: 24px;
  width: 50px;
  height: 50px;
  color: black;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
}

@media (min-width: 768px) {

  .slick-prev,
  .slick-next {
    font-size: 30px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 50px;
  }
}

@media (min-width: 1024px) {

  .slick-prev,
  .slick-next {
    font-size: 40px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 60px;
  }
}

/* Gloving Border */
.fancy {
  --offset: 3px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border: none;
  display: inline-block;
  padding: 0.5rem 2rem;
  text-align: center;
  font-size: 1.1rem;
  background-color: transparent;
  cursor: pointer;
  color: #1f1f1f;
  border-radius: 8px;
  /* Ensure button itself has rounded corners */
}

/* Conic gradient */
.fancy::before {
  content: "";
  background: conic-gradient(from 180deg at 50% 50%,
      transparent 270deg,
      rgba(255, 255, 255, 0.7),
      transparent);
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  animation: rotate 4s linear infinite;
  z-index: -1;
  border-radius: 50%;
  /* Smooth circular gradient */
}

/* Overlay */
.fancy::after {
  content: "";
  background: inherit;
  position: absolute;
  top: var(--offset);
  left: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  z-index: -1;
  border-radius: inherit;
  /* Use inherit to match the button's border radius */
  background-color: #dcebf3;
  /* Adjust background for better contrast */
}

@keyframes rotate {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.fontResponsive {
  font-size: clamp(0.5rem, 0.3043rem + 3.4783vw);
}

/* Gloving Border1 */
.fancy1 {
  --offset: 3px;

  overflow: hidden;
  position: relative;
  z-index: 1;
  border: none;
  display: inline-block;
  /* padding: 0.5rem 2rem; */
  padding: 0.5rem;
  text-align: center;

  background-color: transparent;
  cursor: pointer;
  color: #084466;
  border-radius: 8px;
  /* Ensure button itself has rounded corners */
}

/* Conic gradient */
.fancy1::before {
  content: "";
  background: conic-gradient(from 180deg at 50% 50%,
      transparent 270deg,
      rgba(58, 127, 255, 0.851),
      transparent);
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  animation: rotate 4s linear infinite;
  z-index: -2;
  border-radius: 50%;
  /* Smooth circular gradient */
}

/* Overlay */
.fancy1::after {
  content: "";
  background: inherit;
  position: absolute;
  top: var(--offset);
  left: var(--offset);
  right: var(--offset);
  bottom: var(--offset);
  z-index: -1;
  border-radius: inherit;
  /* Use inherit to match the button's border radius */
  background-color: #dcebf3;
  /* Adjust background for better contrast */
}

/* Dropdown Styles */
.react-flags-select {
  position: relative;
  /* Ensure it doesn't get clipped */
  z-index: 2;
  /* Ensure it is above other elements */
}

/* about  */
.flexNullCenter {
  display: flex;
  align-items: center;
}

.whiteBg {
  background-color: #fff;
}

.lightBg {
  background-color: #f5f5f5;
}

.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extraBold {
  font-weight: 800 !important;
}

.font40 {
  font-size: 1.875rem;
}

.font12 {
  font-size: 0.75rem;
}

.flexNullCenter {
  display: flex;
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add this to your CSS file */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

.sliderHIW {
  width: 100%;
}

.slick-dots li.slick-active button::before {
  opacity: 0.75;
  color: white !important;
}

.slick-dots li button::before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: white !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* language  */
.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
  padding: 0 !important;
}

.ReactFlagsSelect-module_selectValue__152eS,
.ReactFlagsSelect-module_selectOption__3pcgW {
  padding: 1px !important;
  margin: 0 !important;
}

.ReactFlagsSelect-module_selectOptionValue__vS99- {
  justify-content: center !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
  border: none !important;
  margin-top: 0 !important;
  padding: 0 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  max-height: max-content !important;
  overflow: auto !important;
}

.ReactFlagsSelect-module_label__27pw9 {
  font-size: 22px !important;
  color: black !important;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ .ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
  padding: 0 5px !important;
  width: 110%;
  position: absolute;
  left: -10px;
  color: black !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* subscription  */
.subscription-mobile .slick-slide,
.slick-active,
.slick-current {
  display: flex !important;
  justify-content: center;
}

/* checkbox.css */
.peer:checked~.peer-checked\:before {
  content: "✔";
}

.peer:checked~.peer-checked\:bg-customBlue {
  background-color: #1e40af;
  /* Use the custom color you defined */
}

/* strategic  */
.strategic .slick-slide,
.slick-active,
.slick-current {
  height: 100% !important;
}

/* main feature  */
.mainFeature .slick-track {
  height: 100% !important;

  .slick-slide,
  .slick-active,
  .slick-current {
    height: 100% !important;
  }
}

.mainFeature .slick-track .slick-slide,
.slick-active,
.slick-current {
  height: 100% !important;
  margin-top: 9px !important;
}

element.style {
  width: 25px;
  height: 8px;
  border-radius: 15px;
  background: rgb(8, 68, 102);
  display: inline-block;
  translate: none;
  rotate: none;
  scale: none;
  margin-top: 7px;
  transform: scale(1.2, 1.2);
}

#img1-s-image1 {
  width: 100%;
}